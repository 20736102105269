<template>
  <div>
    <Modal
      v-show="isModalVisible"
      :constat="flux.constat"
      :type="flux.level2"
      @close="closeModal"
      @edit="editRepair"
      @evenement="evenement"
    />
    <v-row>
      <v-col cols="12">
        <h2 class="mt10 pt10">TROU / ACCROC / DÉCHIRURE</h2>
        <div class="spacer"></div>
        <div>
          <b>Choisissez le type de réparation que vous souhaitez</b>
        </div>
        <p v-if="['VESTE/BLOUSON', 'AUTRES BAS'].includes(this.flux.level2)" class="mt-5">
          <v-icon>
            mdi-information-slab-circle-outline
          </v-icon>
          Nous ne traitons pas les trous/accrocs sur les vestes et pantalons de costume
        </p>
        <div class="spacer"></div>
        <v-tabs
          v-model="active_tab"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          @change="onChangeTab"
        >

          <template v-if="this.flux.level2 === 'DOUDOUNE'">
            <v-tab href="#empiecement-doudoune">
              EMPIÈCEMENT / CLOISON
            </v-tab>
            <v-tab href="#reparation-doudoune">
              RÉPARATION SIMPLE
            </v-tab>
          </template>

          <v-tab
            v-if="
                        this.flux.level2 == 'MANTEAU'
                        || this.flux.level2 == 'VESTE/BLOUSON'
                        || this.flux.level2 == 'PULL/GILET'
                        || this.flux.level2 =='SWEAT/HOODIE'
                        || this.flux.level2 =='CHEMISE'
                        || this.flux.level2 =='POLO/T.SHIRT/BLOUSE'
                        || this.flux.level2 =='AUTRES BAS'
                        || this.flux.level2 =='SAC A DOS/SAC SOUPLE'
                        || this.flux.level2 =='SALOPETTE/COMBI/ROBE'
                        || this.flux.level2 =='LINGERIE/MAILLOT DE BAIN'
                        || this.flux.level2 =='LINGERIE/MAILLOT DE BAIN'
                        || this.flux.level2 =='ACCESSOIRES DIVERS'
                        "
            href="#reparation_simple"
          >
            COUTURE SIMPLE
          </v-tab>
          <v-tab v-if="[
                        'AUTRES BAS',
                        'MANTEAU',
                        'SALOPETTE/COMBI/ROBE',
                        'SWEAT/HOODIE',
                        'VESTE/BLOUSON'
                      ].includes(this.flux.level2)" href="#bord-cote">
            BORD COTE
          </v-tab>
          <v-tab v-if="false && this.flux.level2 == 'PULL/GILET'
                    " href="#mites">
            REMAILLAGE / TROU DE MITE
          </v-tab>
          <v-tab
            v-if="
                        this.flux.level2 == 'MANTEAU'
                        || this.flux.level2 == 'VESTE/BLOUSON'
                        || this.flux.level2 =='POLO/T.SHIRT/BLOUSE'
                        || this.flux.level2 =='AUTRES BAS'
                        || this.flux.level2 =='SAC A DOS/SAC SOUPLE'
                        || this.flux.level2 =='SALOPETTE/COMBI/ROBE'
                        || this.flux.level2 =='LINGERIE/MAILLOT DE BAIN'
                        || this.flux.level2 =='ACCESSOIRES DIVERS'
                        " href="#empiecement">
            EMPIÈCEMENT
          </v-tab>
          <v-tab
            v-if="this.flux.level2 == 'MANTEAU' || this.flux.level2 == 'VESTE/BLOUSON' || this.flux.level2 =='SWEAT/HOODIE'  || this.flux.level2 =='POLO/T.SHIRT/BLOUSE' || this.flux.level2 =='AUTRES BAS' || this.flux.level2 =='SAC A DOS/SAC SOUPLE' || this.flux.level2 =='SALOPETTE/COMBI/ROBE' || this.flux.level2 =='LINGERIE/MAILLOT DE BAIN' || this.flux.level2 =='ACCESSOIRES DIVERS'"
            href="#patch">
            PATCH
          </v-tab>
          <v-tab
            v-if="this.flux.level2 == 'MANTEAU' || this.flux.level2 == 'VESTE/BLOUSON' || this.flux.level2 == 'PULL/GILET' || this.flux.level2 =='SWEAT/HOODIE' || this.flux.level2 =='CHEMISE'"
            href="#coudieres">
            COUDIÈRES
          </v-tab>
          <v-tab v-if="['AUTRES BAS', 'SALOPETTE/COMBI/ROBE'].includes(this.flux.level2)" href="#genouillere">
            GENOUILLÈRES
          </v-tab>

          <v-tab-item id="reparation_simple">
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
            <!-- <Reparationsimple @showmodal="showModal" @evenement="evenement"/> -->
          </v-tab-item>
          <v-tab-item id="bord-cote">
            <!-- <Coudiere @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="empiecement-doudoune">
            <KeepAlive>
              <component
                v-bind:is="flux.activeTab"
                @evenement="evenement"
                @showmodal="showModal"
              />
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="reparation-doudoune">
            <KeepAlive>
              <component
                v-bind:is="flux.activeTab"
                @changeTab="onChangeTab"
                @evenement="evenement" @showmodal="showModal"
              />
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="empiecement">
            <!-- <Empiecement @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component
                v-bind:is="flux.activeTab"
                @evenement="evenement"
                @showmodal="showModal"
              />
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="patch">
            <!-- <Patch @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="coudieres">
            <!-- <Coudiere @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="mites">
            <!-- <Coudiere @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="genouillere">
            <!-- <Coudiere @showmodal="showModal" @evenement="evenement"/> -->
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import Modal from '../Modal.vue';


export default {
  name: 'Trou',
  data() {
    return {
      tabName: null,
      isModalVisible: false,
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
    active_tab: {
      // getter
      get() {
        return this.flux.activeTab
      },
      // setter
      set(newValue) {
        this.setActiveTab(newValue)
      }
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setActiveTab']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    onChangeTab(value) {
      this.tabName = value
      this.setActiveTab(value)
    },
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement($value) {
      this.$emit('evenement', $value)
    },
    showModal() {
      this.$emit('showmodal', this.flux.level2)
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components: {
    Modal,
    'reparation_simple': () => import('./reparation_simple.vue'),
    'empiecement': () => import('./empiecement.vue'),
    'mites': () => import('./mites.vue'),
    'patch': () => import('./patch.vue'),
    'coudieres': () => import('./coudiere.vue'),
    'genouillere': () => import('./genouillere.vue'),
    'bord-cote': () => import('./bord-cote.vue'),
    'reparation-doudoune': () => import('./reparation-doudoune.vue'),
    'empiecement-doudoune': () => import('./empiecement-doudoune.vue'),
  }
}
</script>
